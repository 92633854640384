import CreateBookingParameters from "../types/createBookingRequest/CreateBookingParameters";
import {toCarrierAndNumber} from '../globalFunctions/flightNumber';
import FlightInformation from "../types/flightInformation/FlightInformation";

const BookingService = {
  async createBookingRequest(bookingParams: CreateBookingParameters, specificError: boolean = false) {
    const endpoint = 'bookingCreateRequests';
    const url = `${process.env.REACT_APP_API_URL}/${endpoint}`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bookingParams)
    });

    if (!response.ok) {
      if (specificError) {
        throw new Error(`Network response was not ok: ${await response.text()}`)
      }
      throw new Error('Network response was not ok');
    }

    return response.json();
  },

  async cancelBookingRequest(bookingReference: string) {
    const endpoint = 'bookingCancelRequests';
    const url = `${process.env.REACT_APP_API_URL}/${endpoint}`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        bookingReference
      })
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${await response.text()}`);
    }

    return response.json();
  },

  async getFlightInformation(flightId: string): Promise<FlightInformation> {
    var endpoint = `flights/${flightId}/information`;
    const url = `${process.env.REACT_APP_API_URL}/${endpoint}`;
    const response = await fetch(url, {
      method: 'GET',
    });
  
    if (!response.ok) {
      throw new Error(`Failed to fetch flight information: ${response.statusText}`);
    }
  
    const data: FlightInformation = await response.json();
    return data;
  },

  getRoutes(departureDate: string | null = null, returnDate: string | null = null) {
    var endpoint = 'route?';
    if (departureDate) endpoint = endpoint + `date=${departureDate}&`;
    if (returnDate) endpoint = endpoint + `returnDate=${returnDate}&`;
    const url = `${process.env.REACT_APP_API_URL}/${endpoint}`;
    return fetch(url, {
      method: 'GET'
    }).then((res) => res.json());
  },

  getDates(origin: string | null, destination: string | null, flightNumber: string | null, year: string, month: string, onlyRoutes: boolean | null = null) {
    var split = toCarrierAndNumber(flightNumber);
    var endpoint = `route/departureDates?year=${year}&month=${month}&`;
    if (origin) endpoint = endpoint + `origin=${origin}&`
    if (destination) endpoint = endpoint + `destination=${destination}&`
    if (split) endpoint = endpoint + `carrierCode=${split.carrierCode}&flightNumber=${split.flightNumber}&`
    if (onlyRoutes) endpoint = endpoint + `shouldSearchOnlyRoute=${onlyRoutes}`
    const url = `${process.env.REACT_APP_API_URL}/${endpoint}`;
    
    return fetch(url, {
      method: 'GET'
    }).then((res) => res.json());
  }
};

export default BookingService;
